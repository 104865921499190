@import "primereact/resources/primereact.css";
@import "primeflex/primeflex.css";
@import "primereact/resources/themes/nano/theme.css";
@import "primeicons/primeicons.css";
@import "@nosferatu500/react-sortable-tree/style.css";
@import "./theme.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

.p-tabview, .p-tabview-panels, .p-tabview-panel {
  height: 100%;
}

.p-tabview-panels {
  padding: 0!important;
}

.p-chips, .p-chips-multiple-container {
  width: 100%;
}