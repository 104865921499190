.tabview {
    width: 100%;
    height: 100%;
}

.treeview {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.editorview {
    width: 100%;
    height: 100%;
}

.editorHeader {
    height: 40px;
    border-bottom: 2px solid #dee2e6;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    justify-content: space-between;
}

.project {
    height: 40px;
    border-bottom: 2px solid #dee2e6;
    display: flex;
    align-items: center;
    padding: 0 0.75rem;
    flex-grow: 1;
    justify-content: space-between;
}

.selector {
    display: flex;
    gap: 0.75rem;
    position: relative;
    flex-grow: 1;
    max-width: 60%;
}

.avatar {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    justify-content: flex-end;
}

.search {
    height: 34px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
}

.treeFooter {

}

.footerButtons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.historyView {
    width: 100%;
}

.changes {
    display: flex;
    flex-direction: column;
}

.comments {
    display: flex;
    flex-direction: column;
}

.history {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem 0;
}

.importer {
    height: 100%;
}

.importer_wait {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: 2rem;
}

.importer_content {
    padding: 0.75rem;
    height: 100%;
}

.changed_item {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 100%;
}

.item_changes {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: flex-end;
    align-items: center;
}

.changes_form {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.changes_form textarea {
    width: 100%;
}

.change_footer {
    display: flex;
    justify-content: space-between;
}