:root {
    --surface-a: #ffffff;
    --surface-b: #f2f4f8;
    --surface-c: #dde1e6;
    --surface-d: #dee2e6;
    --surface-e: #ffffff;
    --surface-f: #ffffff;
    --text-color: #343a3f;
    --text-color-secondary: #697077;
    --primary-color: #000000;
    --primary-color-text: #ffffff;
    --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    --surface-0: #ffffff;
    --surface-50: #eff0f0;
    --surface-100: #dfe0e1;
    --surface-200: #bfc1c4;
    --surface-300: #9fa3a6;
    --surface-400: #7f8489;
    --surface-500: #5f656b;
    --surface-600: #4c5156;
    --surface-700: #393d40;
    --surface-800: #26282b;
    --surface-900: #131415;
    --gray-50: #eff0f0;
    --gray-100: #dfe0e1;
    --gray-200: #bfc1c4;
    --gray-300: #9fa3a6;
    --gray-400: #7f8489;
    --gray-500: #5f656b;
    --gray-600: #4c5156;
    --gray-700: #393d40;
    --gray-800: #26282b;
    --gray-900: #131415;
    --content-padding: 0.5rem;
    --inline-spacing: 0.5rem;
    --border-radius: 1px;
    --surface-ground: #f2f4f8;
    --surface-section: #ffffff;
    --surface-card: #ffffff;
    --surface-overlay: #ffffff;
    --surface-border: #dee2e6;
    --surface-hover: #dde1e6;
    --focus-ring: 0 0 0 0.2rem #90c9f5;
    --maskbg: rgba(0, 0, 0, 0.4);
    --highlight-bg: #44A1D9;
    --highlight-text-color: #ffffff;
    color-scheme: light
}

* {
    box-sizing: border-box
}

.p-component {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal
}

.p-component-overlay {
    background-color: rgba(0, 0, 0, .4);
    transition-duration: .2s
}

.p-disabled, .p-component:disabled {
    opacity: .6
}

.p-error {
    color: #d8222f
}

.p-text-secondary {
    color: #697077
}

.pi {
    font-size: 1rem
}

.p-icon {
    width: 1rem;
    height: 1rem
}

.p-link {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    border-radius: 1px
}

.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards
}

.p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: rgba(0, 0, 0, 0)
    }
    to {
        background-color: var(--maskbg)
    }
}

@keyframes p-component-overlay-leave-animation {
    from {
        background-color: var(--maskbg)
    }
    to {
        background-color: rgba(0, 0, 0, 0)
    }
}

:root {
    --blue-50: #f4f9fd;
    --blue-100: #c9e2f4;
    --blue-200: #9ecbec;
    --blue-300: #74b3e4;
    --blue-400: #499cdb;
    --blue-500: #1e85d3;
    --blue-600: #1a71b3;
    --blue-700: #155d94;
    --blue-800: #114974;
    --blue-900: #0c3554;
    --green-50: #f5fbf7;
    --green-100: #cfead7;
    --green-200: #a9dab7;
    --green-300: #84ca97;
    --green-400: #5eb978;
    --green-500: #38a958;
    --green-600: #30904b;
    --green-700: #27763e;
    --green-800: #1f5d30;
    --green-900: #164423;
    --yellow-50: #fefcf5;
    --yellow-100: #fbf0cf;
    --yellow-200: #f9e4a8;
    --yellow-300: #f6d982;
    --yellow-400: #f3cd5b;
    --yellow-500: #f0c135;
    --yellow-600: #cca42d;
    --yellow-700: #a88725;
    --yellow-800: #846a1d;
    --yellow-900: #604d15;
    --cyan-50: #f4fcfe;
    --cyan-100: #caf0fc;
    --cyan-200: #a0e4fa;
    --cyan-300: #75d9f8;
    --cyan-400: #4bcdf5;
    --cyan-500: #21c1f3;
    --cyan-600: #1ca4cf;
    --cyan-700: #1787aa;
    --cyan-800: #126a86;
    --cyan-900: #0d4d61;
    --pink-50: #fdf6f6;
    --pink-100: #f5d3d6;
    --pink-200: #eeb0b5;
    --pink-300: #e68e94;
    --pink-400: #df6b74;
    --pink-500: #d74853;
    --pink-600: #b73d47;
    --pink-700: #97323a;
    --pink-800: #76282e;
    --pink-900: #561d21;
    --indigo-50: #f3f6fc;
    --indigo-100: #c6d4f0;
    --indigo-200: #99b2e4;
    --indigo-300: #6b90d8;
    --indigo-400: #3e6ecc;
    --indigo-500: #114cc0;
    --indigo-600: #0e41a3;
    --indigo-700: #0c3586;
    --indigo-800: #092a6a;
    --indigo-900: #071e4d;
    --teal-50: #f3f9f8;
    --teal-100: #c6e0df;
    --teal-200: #98c7c5;
    --teal-300: #6baeac;
    --teal-400: #3d9692;
    --teal-500: #107d79;
    --teal-600: #0e6a67;
    --teal-700: #0b5855;
    --teal-800: #094543;
    --teal-900: #063230;
    --orange-50: #fef9f5;
    --orange-100: #fbe4cf;
    --orange-200: #f9cfa8;
    --orange-300: #f6ba82;
    --orange-400: #f3a45b;
    --orange-500: #f08f35;
    --orange-600: #cc7a2d;
    --orange-700: #a86425;
    --orange-800: #844f1d;
    --orange-900: #603915;
    --bluegray-50: #f8f8f8;
    --bluegray-100: #dbddde;
    --bluegray-200: #bfc2c5;
    --bluegray-300: #a2a6ab;
    --bluegray-400: #868b91;
    --bluegray-500: #697077;
    --bluegray-600: #595f65;
    --bluegray-700: #4a4e53;
    --bluegray-800: #3a3e41;
    --bluegray-900: #2a2d30;
    --purple-50: #f9f6ff;
    --purple-100: #e3d3fd;
    --purple-200: #ccb1fc;
    --purple-300: #b68efb;
    --purple-400: #9f6cf9;
    --purple-500: #8949f8;
    --purple-600: #743ed3;
    --purple-700: #6033ae;
    --purple-800: #4b2888;
    --purple-900: #371d63;
    --red-50: #fff4f5;
    --red-100: #ffccce;
    --red-200: #ffa4a7;
    --red-300: #ff7c80;
    --red-400: #ff5359;
    --red-500: #ff2b32;
    --red-600: #d9252b;
    --red-700: #b31e23;
    --red-800: #8c181c;
    --red-900: #661114;
    --primary-50: #f2f2f2;
    --primary-100: #c2c2c2;
    --primary-200: #919191;
    --primary-300: #616161;
    --primary-400: #303030;
    --primary-500: black;
    --primary-600: black;
    --primary-700: black;
    --primary-800: black;
    --primary-900: black
}

.p-autocomplete .p-autocomplete-loader {
    right: .25rem
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
    right: 2.607rem
}

.p-autocomplete .p-autocomplete-multiple-container {
    padding: .125rem .25rem
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
    padding: .125rem 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #343a3f;
    padding: 0;
    margin: 0
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
    padding: .125rem .25rem;
    margin-right: .5rem;
    background: #44a1d9;
    color: #fff;
    border-radius: 1px
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
    margin-left: .5rem
}

.p-autocomplete.p-invalid.p-component > .p-inputtext {
    border-color: #d8222f
}

.p-autocomplete-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: .25rem 0
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
    color: #343a3f;
    background: #dde1e6
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600
}

.p-calendar.p-invalid.p-component > .p-inputtext {
    border-color: #d8222f
}

.p-datepicker {
    padding: .25rem;
    background: #fff;
    color: #343a3f;
    border: 1px solid #a5acb3;
    border-radius: 1px
}

.p-datepicker:not(.p-datepicker-inline) {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
    background: #fff
}

.p-datepicker .p-datepicker-header {
    padding: .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-datepicker .p-datepicker-header .p-datepicker-prev, .p-datepicker .p-datepicker-header .p-datepicker-next {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus, .p-datepicker .p-datepicker-header .p-datepicker-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
    line-height: 1rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    color: #343a3f;
    transition: background-color .2s, color .2s, box-shadow .2s;
    font-weight: 600;
    padding: .5rem
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year:enabled:hover, .p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month:enabled:hover {
    color: #000
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
    margin-right: .5rem
}

.p-datepicker table {
    font-size: 1rem;
    margin: .25rem 0
}

.p-datepicker table th {
    padding: .5rem
}

.p-datepicker table th > span {
    width: 1.75rem;
    height: 1.75rem
}

.p-datepicker table td {
    padding: .5rem
}

.p-datepicker table td > span {
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    transition: box-shadow .2s;
    border: 1px solid rgba(0, 0, 0, 0)
}

.p-datepicker table td > span.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-datepicker table td > span:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datepicker table td.p-datepicker-today > span {
    background: #a5acb3;
    color: #343a3f;
    border-color: rgba(0, 0, 0, 0)
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-datepicker .p-datepicker-buttonbar {
    padding: .5rem 0;
    border-top: 1px solid #dee2e6
}

.p-datepicker .p-datepicker-buttonbar .p-button {
    width: auto
}

.p-datepicker .p-timepicker {
    border-top: 1px solid #dee2e6;
    padding: .5rem
}

.p-datepicker .p-timepicker button {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-datepicker .p-timepicker button:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-datepicker .p-timepicker button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datepicker .p-timepicker button:last-child {
    margin-top: .2em
}

.p-datepicker .p-timepicker span {
    font-size: 1rem
}

.p-datepicker .p-timepicker > div {
    padding: 0 .5rem
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
    border-top: 0 none
}

.p-datepicker .p-monthpicker {
    margin: .25rem 0
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
    padding: .5rem;
    transition: box-shadow .2s;
    border-radius: 1px
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-datepicker .p-yearpicker {
    margin: .25rem 0
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
    padding: .5rem;
    transition: box-shadow .2s;
    border-radius: 1px
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
    border-left: 1px solid #dee2e6;
    padding-right: .25rem;
    padding-left: .25rem;
    padding-top: 0;
    padding-bottom: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
    padding-left: 0
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
    padding-right: 0;
    border-left: 0 none
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
    background: #dde1e6
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
    background: #dde1e6
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
    background: #dde1e6
}

.p-datepicker:not(.p-disabled) .p-yearpicker .p-yearpicker-year:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

@media screen and (max-width: 769px) {
    .p-datepicker table th, .p-datepicker table td {
        padding: 0
    }
}

.p-cascadeselect {
    background: #fff;
    border: 1px solid #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.p-cascadeselect:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-cascadeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-cascadeselect .p-cascadeselect-label {
    background: rgba(0, 0, 0, 0);
    border: 0 none;
    padding: .25rem .25rem
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
    color: #697077
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
    outline: 0 none;
    box-shadow: none
}

.p-cascadeselect .p-cascadeselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #697077;
    width: 2.357rem;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-cascadeselect.p-invalid.p-component {
    border-color: #d8222f
}

.p-cascadeselect-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-cascadeselect-panel .p-cascadeselect-items {
    padding: .25rem 0
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
    margin: 0;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
    padding: .25rem .5rem
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #343a3f;
    background: #dde1e6
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
    font-size: .875rem
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-input-filled .p-cascadeselect {
    background: #f2f4f8
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
    background-color: #f2f4f8
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
    background-color: #fff
}

.p-checkbox {
    width: 14px;
    height: 14px
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #a5acb3;
    background: #fff;
    width: 14px;
    height: 14px;
    color: #343a3f;
    border-radius: 1px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    transition-duration: .2s;
    color: #fff;
    font-size: 12px
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
    width: 12px;
    height: 12px
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #000;
    background: #000
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
    border-color: #000;
    background: #000;
    color: #fff
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #1174c0
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #000;
    background: #000;
    color: #fff
}

.p-checkbox.p-invalid > .p-checkbox-box {
    border-color: #d8222f
}

.p-input-filled .p-checkbox .p-checkbox-box {
    background-color: #f2f4f8
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #000
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: #f2f4f8
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: #000
}

.p-chips .p-chips-multiple-container {
    padding: .125rem .25rem
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: .125rem .25rem;
    margin-right: .5rem;
    background: #dee2e6;
    color: #343a3f;
    border-radius: 16px
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-left: .5rem
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: .125rem 0
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #343a3f;
    padding: 0;
    margin: 0
}

.p-chips.p-invalid.p-component > .p-inputtext {
    border-color: #d8222f
}

.p-colorpicker-preview {
    width: 2rem;
    height: 2rem
}

.p-colorpicker-panel {
    background: #323232;
    border: 1px solid #191919
}

.p-colorpicker-panel .p-colorpicker-color-handle, .p-colorpicker-panel .p-colorpicker-hue-handle {
    border-color: #fff
}

.p-colorpicker-overlay-panel {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-dropdown {
    background: #fff;
    border: 1px solid #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 1.25rem
}

.p-dropdown .p-dropdown-label {
    background: rgba(0, 0, 0, 0);
    border: 0 none
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #697077
}

.p-dropdown .p-dropdown-label:enabled:focus {
    outline: 0 none;
    box-shadow: none
}

.p-dropdown .p-dropdown-trigger {
    background: rgba(0, 0, 0, 0);
    color: #697077;
    width: 2.357rem;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-dropdown .p-dropdown-clear-icon {
    color: #697077;
    right: 2.357rem
}

.p-dropdown.p-invalid.p-component {
    border-color: #d8222f
}

.p-dropdown-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-dropdown-panel .p-dropdown-header {
    padding: .25rem .5rem;
    border-bottom: 0 none;
    color: #343a3f;
    background: #f2f4f8;
    margin: 0;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    padding-right: 1.25rem;
    margin-right: -1.25rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
    right: .25rem;
    color: #697077
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter {
    padding-right: 2.5rem;
    margin-right: -2.5rem
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-clearable-filter .p-dropdown-filter-clear-icon {
    right: 1.5rem
}

.p-dropdown-panel .p-dropdown-items {
    padding: .25rem 0
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #343a3f;
    background: #dde1e6
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
    padding: .25rem .5rem;
    color: #343a3f;
    background: rgba(0, 0, 0, 0)
}

.p-input-filled .p-dropdown {
    background: #f2f4f8
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
    background-color: #f2f4f8
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    background-color: #fff
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
    background-color: rgba(0, 0, 0, 0)
}

.p-editor-container .p-editor-toolbar {
    background: #f2f4f8;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid #dee2e6
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: #697077
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: #697077
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: #697077
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px;
    padding: .25rem 0
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: #343a3f
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: #343a3f;
    background: #dde1e6
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: .25rem .5rem
}

.p-editor-container .p-editor-content {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-editor-container .p-editor-content.ql-snow {
    border: 1px solid #dee2e6
}

.p-editor-container .p-editor-content .ql-editor {
    background: #fff;
    color: #343a3f;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-editor-container .ql-snow.ql-toolbar button:hover, .p-editor-container .ql-snow.ql-toolbar button:focus {
    color: #343a3f
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: #343a3f
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill, .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: #343a3f
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #000
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #000
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #000
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label, .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #000
}

.p-inputgroup-addon {
    background: #dde1e6;
    color: #697077;
    border-top: 1px solid #a5acb3;
    border-left: 1px solid #a5acb3;
    border-bottom: 1px solid #a5acb3;
    padding: .25rem .25rem;
    min-width: 2.357rem
}

.p-inputgroup-addon:last-child {
    border-right: 1px solid #a5acb3
}

.p-inputgroup > .p-component, .p-inputgroup > .p-inputwrapper > .p-inputtext, .p-inputgroup > .p-float-label > .p-component {
    border-radius: 0;
    margin: 0
}

.p-inputgroup > .p-component + .p-inputgroup-addon, .p-inputgroup > .p-inputwrapper > .p-inputtext + .p-inputgroup-addon, .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
    border-left: 0 none
}

.p-inputgroup > .p-component:focus, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus, .p-inputgroup > .p-float-label > .p-component:focus {
    z-index: 1
}

.p-inputgroup > .p-component:focus ~ label, .p-inputgroup > .p-inputwrapper > .p-inputtext:focus ~ label, .p-inputgroup > .p-float-label > .p-component:focus ~ label {
    z-index: 1
}

.p-inputgroup-addon:first-child, .p-inputgroup button:first-child, .p-inputgroup input:first-child, .p-inputgroup > .p-inputwrapper:first-child, .p-inputgroup > .p-inputwrapper:first-child > .p-inputtext {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-inputgroup .p-float-label:first-child input {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-inputgroup-addon:last-child, .p-inputgroup button:last-child, .p-inputgroup input:last-child, .p-inputgroup > .p-inputwrapper:last-child, .p-inputgroup > .p-inputwrapper:last-child > .p-inputtext {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-inputgroup .p-float-label:last-child input {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-fluid .p-inputgroup .p-button {
    width: auto
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
    width: 2.357rem
}

.p-inputnumber.p-invalid.p-component > .p-inputtext {
    border-color: #d8222f
}

.p-inputswitch {
    width: 2rem;
    height: 1.155rem
}

.p-inputswitch .p-inputswitch-slider {
    background: #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 30px
}

.p-inputswitch .p-inputswitch-slider:before {
    background: #fff;
    width: .825rem;
    height: .825rem;
    left: .165rem;
    margin-top: -0.4125rem;
    border-radius: 50%;
    transition-duration: .2s
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(0.825rem)
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #929ba3
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #000
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #fff
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #000
}

.p-inputswitch.p-invalid .p-inputswitch-slider {
    border-color: #d8222f
}

.p-inputtext {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    color: #343a3f;
    background: #fff;
    padding: .25rem .25rem;
    border: 1px solid #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    appearance: none;
    border-radius: 1px
}

.p-inputtext:enabled:hover {
    border-color: #1174c0
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-inputtext.p-invalid.p-component {
    border-color: #d8222f
}

.p-inputtext.p-inputtext-sm {
    font-size: .875rem;
    padding: .21875rem .21875rem
}

.p-inputtext.p-inputtext-lg {
    font-size: 1.25rem;
    padding: .3125rem .3125rem
}

.p-float-label > label {
    left: .25rem;
    color: #697077;
    transition-duration: .2s
}

.p-float-label > label.p-error {
    color: #d8222f
}

.p-input-icon-left > i:first-of-type, .p-input-icon-left > svg:first-of-type, .p-input-icon-left > .p-input-prefix {
    left: .25rem;
    color: #697077
}

.p-input-icon-left > .p-inputtext {
    padding-left: 1.5rem
}

.p-input-icon-left.p-float-label > label {
    left: 1.5rem
}

.p-input-icon-right > i:last-of-type, .p-input-icon-right > svg:last-of-type, .p-input-icon-right > .p-input-suffix {
    right: .25rem;
    color: #697077
}

.p-input-icon-right > .p-inputtext {
    padding-right: 1.5rem
}

::-webkit-input-placeholder {
    color: #697077
}

:-moz-placeholder {
    color: #697077
}

::-moz-placeholder {
    color: #697077
}

:-ms-input-placeholder {
    color: #697077
}

.p-input-filled .p-inputtext {
    background-color: #f2f4f8
}

.p-input-filled .p-inputtext:enabled:hover {
    background-color: #f2f4f8
}

.p-input-filled .p-inputtext:enabled:focus {
    background-color: #fff
}

.p-inputtext-sm .p-inputtext {
    font-size: .875rem;
    padding: .21875rem .21875rem
}

.p-inputtext-lg .p-inputtext {
    font-size: 1.25rem;
    padding: .3125rem .3125rem
}

.p-listbox {
    background: #fff;
    color: #343a3f;
    border: 1px solid #a5acb3;
    border-radius: 1px
}

.p-listbox .p-listbox-header {
    padding: .25rem .5rem;
    border-bottom: 0 none;
    color: #343a3f;
    background: #f2f4f8;
    margin: 0;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 1.25rem
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: .25rem;
    color: #697077
}

.p-listbox .p-listbox-list {
    padding: .25rem 0
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    transition: box-shadow .2s;
    border-radius: 0
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600
}

.p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: .25rem .5rem;
    color: #343a3f;
    background: rgba(0, 0, 0, 0)
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: #343a3f;
    background: #dde1e6
}

.p-listbox.p-invalid {
    border-color: #d8222f
}

.p-mention-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-mention-panel .p-mention-items {
    padding: .25rem 0
}

.p-mention-panel .p-mention-items .p-mention-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-mention-panel .p-mention-items .p-mention-item:hover {
    color: #343a3f;
    background: #dde1e6
}

.p-mention-panel .p-mention-items .p-mention-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-multiselect {
    background: #fff;
    border: 1px solid #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.p-multiselect:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-multiselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.25rem
}

.p-multiselect .p-multiselect-label {
    padding: .25rem .25rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-multiselect .p-multiselect-label.p-placeholder {
    color: #697077
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    padding: .125rem .25rem;
    margin-right: .5rem;
    background: #dee2e6;
    color: #343a3f;
    border-radius: 16px
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
    margin-left: .5rem
}

.p-multiselect .p-multiselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #697077;
    width: 2.357rem;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-multiselect .p-multiselect-clear-icon {
    color: #697077;
    right: 2.357rem
}

.p-multiselect.p-invalid.p-component {
    border-color: #d8222f
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: .125rem .25rem
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label.p-multiselect-items-label {
    padding: .25rem .25rem
}

.p-inputwrapper-filled.p-multiselect.p-multiselect-clearable .p-multiselect-label {
    padding-right: 1.25rem
}

.p-multiselect-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-multiselect-panel .p-multiselect-header {
    padding: .25rem .5rem;
    border-bottom: 0 none;
    color: #343a3f;
    background: #f2f4f8;
    margin: 0;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container {
    margin: 0 .5rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-right: 1.25rem
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    right: .25rem;
    color: #697077
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-multiselect-panel .p-multiselect-items {
    padding: .25rem 0
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    color: #343a3f;
    background: #dde1e6
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-right: .5rem
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
    padding: .25rem .5rem;
    color: #343a3f;
    background: rgba(0, 0, 0, 0)
}

.p-input-filled .p-multiselect {
    background: #f2f4f8
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
    background-color: #f2f4f8
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
    background-color: #fff
}

.p-password.p-invalid.p-component > .p-inputtext {
    border-color: #d8222f
}

.p-password-panel {
    padding: .5rem;
    background: #fff;
    color: #343a3f;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px
}

.p-password-panel .p-password-meter {
    margin-bottom: .5rem;
    background: #dee2e6
}

.p-password-panel .p-password-meter .p-password-strength.weak {
    background: #d8222f
}

.p-password-panel .p-password-meter .p-password-strength.medium {
    background: #f0c135
}

.p-password-panel .p-password-meter .p-password-strength.strong {
    background: #207f3b
}

.p-radiobutton {
    width: 14px;
    height: 14px
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #a5acb3;
    background: #fff;
    width: 14px;
    height: 14px;
    color: #343a3f;
    border-radius: 50%;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #1174c0
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 8px;
    height: 8px;
    transition-duration: .2s;
    background-color: #fff
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #000;
    background: #000
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    border-color: #000;
    background: #000;
    color: #fff
}

.p-radiobutton.p-invalid > .p-radiobutton-box {
    border-color: #d8222f
}

.p-radiobutton:focus {
    outline: 0 none
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
    background-color: #f2f4f8
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
    background-color: #f2f4f8
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
    background: #000
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
    background: #000
}

.p-rating {
    gap: .5rem
}

.p-rating .p-rating-item .p-rating-icon {
    color: #343a3f;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    font-size: 1.143rem
}

.p-rating .p-rating-item .p-rating-icon.p-icon {
    width: 1.143rem;
    height: 1.143rem
}

.p-rating .p-rating-item .p-rating-icon.p-rating-cancel {
    color: #e74c3c
}

.p-rating .p-rating-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
    color: #000
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon {
    color: #000
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-item:hover .p-rating-icon.p-rating-cancel {
    color: #c0392b
}

.p-selectbutton .p-button {
    background: #fff;
    border: 1px solid #a5acb3;
    color: #343a3f;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-selectbutton .p-button .p-button-icon-left, .p-selectbutton .p-button .p-button-icon-right {
    color: #697077
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #dde1e6;
    border-color: #a5acb3;
    color: #343a3f
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #697077
}

.p-selectbutton .p-button.p-highlight {
    background: #000;
    border-color: #000;
    color: #fff
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left, .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover {
    background: #000;
    border-color: #000;
    color: #fff
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left, .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

.p-selectbutton.p-invalid > .p-button {
    border-color: #d8222f
}

.p-slider {
    background: #dee2e6;
    border: 0 none;
    border-radius: 1px
}

.p-slider.p-slider-horizontal {
    height: .286rem
}

.p-slider.p-slider-horizontal .p-slider-handle {
    margin-top: -0.5715rem;
    margin-left: -0.5715rem
}

.p-slider.p-slider-vertical {
    width: .286rem
}

.p-slider.p-slider-vertical .p-slider-handle {
    margin-left: -0.5715rem;
    margin-bottom: -0.5715rem
}

.p-slider .p-slider-handle {
    height: 1.143rem;
    width: 1.143rem;
    background: #fff;
    border: 2px solid #000;
    border-radius: 50%;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-slider .p-slider-handle:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-slider .p-slider-range {
    background: #000
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
    background: #000;
    border-color: #000
}

.p-treeselect {
    background: #fff;
    border: 1px solid #a5acb3;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.p-treeselect:not(.p-disabled):hover {
    border-color: #1174c0
}

.p-treeselect:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: #1174c0
}

.p-treeselect .p-treeselect-label {
    padding: .25rem .25rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-treeselect .p-treeselect-label.p-placeholder {
    color: #697077
}

.p-treeselect.p-treeselect-chip .p-treeselect-token {
    padding: .125rem .25rem;
    margin-right: .5rem;
    background: #dee2e6;
    color: #343a3f;
    border-radius: 16px
}

.p-treeselect .p-treeselect-trigger {
    background: rgba(0, 0, 0, 0);
    color: #697077;
    width: 2.357rem;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-treeselect.p-invalid.p-component {
    border-color: #d8222f
}

.p-inputwrapper-filled.p-treeselect.p-treeselect-chip .p-treeselect-label {
    padding: .125rem .25rem
}

.p-treeselect-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-treeselect-panel .p-treeselect-header {
    padding: .25rem .5rem;
    border-bottom: 0 none;
    color: #343a3f;
    background: #f2f4f8;
    margin: 0;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
    margin-right: .5rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter {
    padding-right: 1.25rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container .p-treeselect-filter-icon {
    right: .25rem;
    color: #697077
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter {
    padding-right: 2.5rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container.p-treeselect-clearable-filter .p-treeselect-filter-clear-icon {
    right: 1.5rem
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
    border: 0 none
}

.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
    padding: .25rem .5rem;
    color: #343a3f;
    background: rgba(0, 0, 0, 0)
}

.p-input-filled .p-treeselect {
    background: #f2f4f8
}

.p-input-filled .p-treeselect:not(.p-disabled):hover {
    background-color: #f2f4f8
}

.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
    background-color: #fff
}

.p-togglebutton.p-button {
    background: #fff;
    border: 1px solid #a5acb3;
    color: #343a3f;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right {
    color: #697077
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #dde1e6;
    border-color: #a5acb3;
    color: #343a3f
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left, .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: #697077
}

.p-togglebutton.p-button.p-highlight {
    background: #000;
    border-color: #000;
    color: #fff
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right {
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover {
    background: #000;
    border-color: #000;
    color: #fff
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left, .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
    color: #fff
}

.p-togglebutton.p-button.p-invalid > .p-button {
    border-color: #d8222f
}

.p-button {
    color: #fff;
    background: #000;
    border: 1px solid #000;
    padding: .25rem .5rem;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
    background: #000;
    color: #fff;
    border-color: #000
}

.p-button.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    border: 1px solid
}

.p-button.p-button-outlined:enabled:hover, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, .04);
    color: #000;
    border: 1px solid
}

.p-button.p-button-outlined:enabled:active, .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, .16);
    color: #000;
    border: 1px solid
}

.p-button.p-button-outlined.p-button-plain {
    color: #697077;
    border-color: #697077
}

.p-button.p-button-outlined.p-button-plain:enabled:hover, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #dde1e6;
    color: #697077
}

.p-button.p-button-outlined.p-button-plain:enabled:active, .p-button.p-button-outlined.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #697077
}

.p-button.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-text:enabled:hover, .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, .04);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-text:enabled:active, .p-button.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, .16);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-text.p-button-plain {
    color: #697077
}

.p-button.p-button-text.p-button-plain:enabled:hover, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):hover {
    background: #dde1e6;
    color: #697077
}

.p-button.p-button-text.p-button-plain:enabled:active, .p-button.p-button-text.p-button-plain:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #697077
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-button .p-button-label {
    transition-duration: .2s
}

.p-button .p-button-icon-left {
    margin-right: .5rem
}

.p-button .p-button-icon-right {
    margin-left: .5rem
}

.p-button .p-button-icon-bottom {
    margin-top: .5rem
}

.p-button .p-button-icon-top {
    margin-bottom: .5rem
}

.p-button .p-badge {
    margin-left: .5rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    color: #000;
    background-color: #fff
}

.p-button.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.p-button.p-button-rounded {
    border-radius: 2rem
}

.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: .25rem 0
}

.p-button.p-button-icon-only .p-button-icon-left, .p-button.p-button-icon-only .p-button-icon-right {
    margin: 0
}

.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem
}

.p-button.p-button-sm {
    font-size: .875rem;
    padding: .21875rem .4375rem
}

.p-button.p-button-sm .p-button-icon {
    font-size: .875rem
}

.p-button.p-button-lg {
    font-size: 1.25rem;
    padding: .3125rem .625rem
}

.p-button.p-button-lg .p-button-icon {
    font-size: 1.25rem
}

.p-button.p-button-loading-label-only.p-button-loading-left .p-button-label {
    margin-left: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-right .p-button-label {
    margin-right: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-top .p-button-label {
    margin-top: .5rem
}

.p-button.p-button-loading-label-only.p-button-loading-bottom .p-button-label {
    margin-bottom: .5rem
}

.p-button.p-button-loading-label-only .p-button-loading-icon {
    margin: 0
}

.p-fluid .p-button {
    width: 100%
}

.p-fluid .p-button-icon-only {
    width: 2.357rem
}

.p-fluid .p-buttonset {
    display: flex
}

.p-fluid .p-buttonset .p-button {
    flex: 1
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button, .p-fileupload-choose.p-button-secondary {
    color: #fff;
    background: #697077;
    border: 1px solid #697077
}

.p-button.p-button-secondary:enabled:hover, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:enabled:hover, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):hover {
    background: #5f656b;
    color: #fff;
    border-color: #5f656b
}

.p-button.p-button-secondary:enabled:focus, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-secondary:enabled:focus, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #c2c6c9
}

.p-button.p-button-secondary:enabled:active, .p-button.p-button-secondary:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-buttonset.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary:enabled:active, .p-fileupload-choose.p-button-secondary:not(button):not(a):not(.p-disabled):active {
    background: #545a5f;
    color: #fff;
    border-color: #545a5f
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined, .p-fileupload-choose.p-button-secondary.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #697077;
    border: 1px solid
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(105, 112, 119, .04);
    color: #697077;
    border: 1px solid
}

.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-button.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(105, 112, 119, .16);
    color: #697077;
    border: 1px solid
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text, .p-fileupload-choose.p-button-secondary.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #697077;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-secondary.p-button-text:enabled:hover, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:hover, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(105, 112, 119, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #697077
}

.p-button.p-button-secondary.p-button-text:enabled:active, .p-button.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-secondary.p-button-text:enabled:active, .p-fileupload-choose.p-button-secondary.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(105, 112, 119, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #697077
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: #fff;
    background: #107d79;
    border: 1px solid #107d79
}

.p-button.p-button-info:enabled:hover, .p-button.p-button-info:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info:enabled:hover, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):hover {
    background: #0e716d;
    color: #fff;
    border-color: #0e716d
}

.p-button.p-button-info:enabled:focus, .p-button.p-button-info:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-info:enabled:focus, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #7ceeea
}

.p-button.p-button-info:enabled:active, .p-button.p-button-info:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-buttonset.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info:enabled:active, .p-fileupload-choose.p-button-info:not(button):not(a):not(.p-disabled):active {
    background: #0d6461;
    color: #fff;
    border-color: #0d6461
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined, .p-fileupload-choose.p-button-info.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #107d79;
    border: 1px solid
}

.p-button.p-button-info.p-button-outlined:enabled:hover, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(16, 125, 121, .04);
    color: #107d79;
    border: 1px solid
}

.p-button.p-button-info.p-button-outlined:enabled:active, .p-button.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-info.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(16, 125, 121, .16);
    color: #107d79;
    border: 1px solid
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text, .p-fileupload-choose.p-button-info.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #107d79;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-info.p-button-text:enabled:hover, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-info.p-button-text:enabled:hover, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(16, 125, 121, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #107d79
}

.p-button.p-button-info.p-button-text:enabled:active, .p-button.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-info.p-button-text:enabled:active, .p-fileupload-choose.p-button-info.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(16, 125, 121, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #107d79
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button, .p-fileupload-choose.p-button-success {
    color: #fff;
    background: #207f3b;
    border: 1px solid #207f3b
}

.p-button.p-button-success:enabled:hover, .p-button.p-button-success:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success:enabled:hover, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):hover {
    background: #1d7235;
    color: #fff;
    border-color: #1d7235
}

.p-button.p-button-success:enabled:focus, .p-button.p-button-success:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-success:enabled:focus, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #8fe3a7
}

.p-button.p-button-success:enabled:active, .p-button.p-button-success:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-buttonset.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success:enabled:active, .p-fileupload-choose.p-button-success:not(button):not(a):not(.p-disabled):active {
    background: #1a662f;
    color: #fff;
    border-color: #1a662f
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined, .p-fileupload-choose.p-button-success.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #207f3b;
    border: 1px solid
}

.p-button.p-button-success.p-button-outlined:enabled:hover, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(32, 127, 59, .04);
    color: #207f3b;
    border: 1px solid
}

.p-button.p-button-success.p-button-outlined:enabled:active, .p-button.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-success.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(32, 127, 59, .16);
    color: #207f3b;
    border: 1px solid
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text, .p-fileupload-choose.p-button-success.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #207f3b;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-success.p-button-text:enabled:hover, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-success.p-button-text:enabled:hover, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(32, 127, 59, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #207f3b
}

.p-button.p-button-success.p-button-text:enabled:active, .p-button.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-success.p-button-text:enabled:active, .p-fileupload-choose.p-button-success.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(32, 127, 59, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #207f3b
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button, .p-fileupload-choose.p-button-warning {
    color: #212529;
    background: #f0c135;
    border: 1px solid #f0c135
}

.p-button.p-button-warning:enabled:hover, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning:enabled:hover, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):hover {
    background: #eeb91a;
    color: #212529;
    border-color: #eeb91a
}

.p-button.p-button-warning:enabled:focus, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-warning:enabled:focus, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #f9e6ae
}

.p-button.p-button-warning:enabled:active, .p-button.p-button-warning:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-buttonset.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning:enabled:active, .p-fileupload-choose.p-button-warning:not(button):not(a):not(.p-disabled):active {
    background: #daa710;
    color: #212529;
    border-color: #daa710
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined, .p-fileupload-choose.p-button-warning.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #f0c135;
    border: 1px solid
}

.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(240, 193, 53, .04);
    color: #f0c135;
    border: 1px solid
}

.p-button.p-button-warning.p-button-outlined:enabled:active, .p-button.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-warning.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(240, 193, 53, .16);
    color: #f0c135;
    border: 1px solid
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text, .p-fileupload-choose.p-button-warning.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #f0c135;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-warning.p-button-text:enabled:hover, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-warning.p-button-text:enabled:hover, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(240, 193, 53, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #f0c135
}

.p-button.p-button-warning.p-button-text:enabled:active, .p-button.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-warning.p-button-text:enabled:active, .p-fileupload-choose.p-button-warning.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(240, 193, 53, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #f0c135
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button, .p-fileupload-choose.p-button-help {
    color: #fff;
    background: #8949f8;
    border: 1px solid #8949f8
}

.p-button.p-button-help:enabled:hover, .p-button.p-button-help:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help:enabled:hover, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):hover {
    background: #752af7;
    color: #fff;
    border-color: #752af7
}

.p-button.p-button-help:enabled:focus, .p-button.p-button-help:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-help:enabled:focus, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #d0b6fc
}

.p-button.p-button-help:enabled:active, .p-button.p-button-help:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-buttonset.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help:enabled:active, .p-fileupload-choose.p-button-help:not(button):not(a):not(.p-disabled):active {
    background: #610bf6;
    color: #fff;
    border-color: #610bf6
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined, .p-fileupload-choose.p-button-help.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #8949f8;
    border: 1px solid
}

.p-button.p-button-help.p-button-outlined:enabled:hover, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(137, 73, 248, .04);
    color: #8949f8;
    border: 1px solid
}

.p-button.p-button-help.p-button-outlined:enabled:active, .p-button.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-help.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(137, 73, 248, .16);
    color: #8949f8;
    border: 1px solid
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text, .p-fileupload-choose.p-button-help.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #8949f8;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-help.p-button-text:enabled:hover, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-help.p-button-text:enabled:hover, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(137, 73, 248, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #8949f8
}

.p-button.p-button-help.p-button-text:enabled:active, .p-button.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-help.p-button-text:enabled:active, .p-fileupload-choose.p-button-help.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(137, 73, 248, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #8949f8
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button, .p-fileupload-choose.p-button-danger {
    color: #fff;
    background: #d8222f;
    border: 1px solid #d8222f
}

.p-button.p-button-danger:enabled:hover, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger:enabled:hover, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):hover {
    background: #c21f2a;
    color: #fff;
    border-color: #c21f2a
}

.p-button.p-button-danger:enabled:focus, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):focus, .p-fileupload-choose.p-button-danger:enabled:focus, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):focus {
    box-shadow: 0 0 0 .2rem #f1a5ab
}

.p-button.p-button-danger:enabled:active, .p-button.p-button-danger:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-buttonset.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger:enabled:active, .p-fileupload-choose.p-button-danger:not(button):not(a):not(.p-disabled):active {
    background: #ad1b26;
    color: #fff;
    border-color: #ad1b26
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined, .p-fileupload-choose.p-button-danger.p-button-outlined {
    background-color: rgba(0, 0, 0, 0);
    color: #d8222f;
    border: 1px solid
}

.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):hover {
    background: rgba(216, 34, 47, .04);
    color: #d8222f;
    border: 1px solid
}

.p-button.p-button-danger.p-button-outlined:enabled:active, .p-button.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-outlined:enabled:active, .p-fileupload-choose.p-button-danger.p-button-outlined:not(button):not(a):not(.p-disabled):active {
    background: rgba(216, 34, 47, .16);
    color: #d8222f;
    border: 1px solid
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text, .p-fileupload-choose.p-button-danger.p-button-text {
    background-color: rgba(0, 0, 0, 0);
    color: #d8222f;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-danger.p-button-text:enabled:hover, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-danger.p-button-text:enabled:hover, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):hover {
    background: rgba(216, 34, 47, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #d8222f
}

.p-button.p-button-danger.p-button-text:enabled:active, .p-button.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:not(button):not(a):not(.p-disabled):active, .p-fileupload-choose.p-button-danger.p-button-text:enabled:active, .p-fileupload-choose.p-button-danger.p-button-text:not(button):not(a):not(.p-disabled):active {
    background: rgba(216, 34, 47, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #d8222f
}

.p-button.p-button-link {
    color: #000;
    background: rgba(0, 0, 0, 0);
    border: rgba(0, 0, 0, 0)
}

.p-button.p-button-link:enabled:hover, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, 0);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-link:enabled:hover .p-button-label, .p-button.p-button-link:not(button):not(a):not(.p-disabled):hover .p-button-label {
    text-decoration: underline
}

.p-button.p-button-link:enabled:focus, .p-button.p-button-link:not(button):not(a):not(.p-disabled):focus {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 .2rem #90c9f5;
    border-color: rgba(0, 0, 0, 0)
}

.p-button.p-button-link:enabled:active, .p-button.p-button-link:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, 0);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton {
    border-radius: 1px
}

.p-splitbutton.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    border: 1px solid
}

.p-splitbutton.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, .04);
    color: #000
}

.p-splitbutton.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, .16);
    color: #000
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button {
    color: #697077;
    border-color: #697077
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #dde1e6;
    color: #697077
}

.p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-outlined.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #697077
}

.p-splitbutton.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(0, 0, 0, .04);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(0, 0, 0, .16);
    color: #000;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-text.p-button-plain > .p-button {
    color: #697077
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: #dde1e6;
    color: #697077
}

.p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active, .p-splitbutton.p-button-text.p-button-plain > .p-button:not(button):not(a):not(.p-disabled):active {
    background: #dee2e6;
    color: #697077
}

.p-splitbutton.p-button-raised {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12)
}

.p-splitbutton.p-button-rounded {
    border-radius: 2rem
}

.p-splitbutton.p-button-rounded > .p-button {
    border-radius: 2rem
}

.p-splitbutton.p-button-sm > .p-button {
    font-size: .875rem;
    padding: .21875rem .4375rem
}

.p-splitbutton.p-button-sm > .p-button .p-button-icon {
    font-size: .875rem
}

.p-splitbutton.p-button-lg > .p-button {
    font-size: 1.25rem;
    padding: .3125rem .625rem
}

.p-splitbutton.p-button-lg > .p-button .p-button-icon {
    font-size: 1.25rem
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #697077;
    border: 1px solid
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(105, 112, 119, .04);
    color: #697077
}

.p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(105, 112, 119, .16);
    color: #697077
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #697077;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(105, 112, 119, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #697077
}

.p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-secondary.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(105, 112, 119, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #697077
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #107d79;
    border: 1px solid
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(16, 125, 121, .04);
    color: #107d79
}

.p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(16, 125, 121, .16);
    color: #107d79
}

.p-splitbutton.p-button-info.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #107d79;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(16, 125, 121, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #107d79
}

.p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-info.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(16, 125, 121, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #107d79
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #207f3b;
    border: 1px solid
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(32, 127, 59, .04);
    color: #207f3b
}

.p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(32, 127, 59, .16);
    color: #207f3b
}

.p-splitbutton.p-button-success.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #207f3b;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(32, 127, 59, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #207f3b
}

.p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-success.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(32, 127, 59, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #207f3b
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #f0c135;
    border: 1px solid
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(240, 193, 53, .04);
    color: #f0c135
}

.p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(240, 193, 53, .16);
    color: #f0c135
}

.p-splitbutton.p-button-warning.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #f0c135;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(240, 193, 53, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #f0c135
}

.p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-warning.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(240, 193, 53, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #f0c135
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #8949f8;
    border: 1px solid
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(137, 73, 248, .04);
    color: #8949f8
}

.p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(137, 73, 248, .16);
    color: #8949f8
}

.p-splitbutton.p-button-help.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #8949f8;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(137, 73, 248, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #8949f8
}

.p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-help.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(137, 73, 248, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #8949f8
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #d8222f;
    border: 1px solid
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(216, 34, 47, .04);
    color: #d8222f
}

.p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-outlined > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(216, 34, 47, .16);
    color: #d8222f
}

.p-splitbutton.p-button-danger.p-button-text > .p-button {
    background-color: rgba(0, 0, 0, 0);
    color: #d8222f;
    border-color: rgba(0, 0, 0, 0)
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):hover {
    background: rgba(216, 34, 47, .04);
    border-color: rgba(0, 0, 0, 0);
    color: #d8222f
}

.p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active, .p-splitbutton.p-button-danger.p-button-text > .p-button:not(button):not(a):not(.p-disabled):active {
    background: rgba(216, 34, 47, .16);
    border-color: rgba(0, 0, 0, 0);
    color: #d8222f
}

.p-speeddial-button.p-button.p-button-icon-only {
    width: 2.75rem;
    height: 2.75rem
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
    font-size: 1rem
}

.p-speeddial-button.p-button.p-button-icon-only .p-button-icon.p-icon {
    width: 1rem;
    height: 1rem
}

.p-speeddial-action {
    width: 2.25rem;
    height: 2.25rem;
    background: #343a3f;
    color: #fff
}

.p-speeddial-action:hover {
    background: #21272a;
    color: #fff
}

.p-speeddial-direction-up .p-speeddial-item {
    margin: .25rem
}

.p-speeddial-direction-up .p-speeddial-item:first-child {
    margin-bottom: .5rem
}

.p-speeddial-direction-down .p-speeddial-item {
    margin: .25rem
}

.p-speeddial-direction-down .p-speeddial-item:first-child {
    margin-top: .5rem
}

.p-speeddial-direction-left .p-speeddial-item {
    margin: 0 .25rem
}

.p-speeddial-direction-left .p-speeddial-item:first-child {
    margin-right: .5rem
}

.p-speeddial-direction-right .p-speeddial-item {
    margin: 0 .25rem
}

.p-speeddial-direction-right .p-speeddial-item:first-child {
    margin-left: .5rem
}

.p-speeddial-circle .p-speeddial-item, .p-speeddial-semi-circle .p-speeddial-item, .p-speeddial-quarter-circle .p-speeddial-item {
    margin: 0
}

.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child, .p-speeddial-semi-circle .p-speeddial-item:first-child, .p-speeddial-semi-circle .p-speeddial-item:last-child, .p-speeddial-quarter-circle .p-speeddial-item:first-child, .p-speeddial-quarter-circle .p-speeddial-item:last-child {
    margin: 0
}

.p-speeddial-mask {
    background-color: rgba(0, 0, 0, .4)
}

.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s;
    margin: .5rem
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover, .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-carousel .p-carousel-content .p-carousel-prev:focus, .p-carousel .p-carousel-content .p-carousel-next:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-carousel .p-carousel-indicators {
    padding: 1rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
    margin-right: .5rem;
    margin-bottom: .5rem
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #dde1e6;
    width: 2rem;
    height: .5rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 0
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
    background: #dee2e6
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
    background: #44a1d9;
    color: #fff
}

.p-datatable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-datatable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-datatable .p-datatable-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-datatable .p-datatable-footer {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #343a3f;
    background: #f2f4f8;
    transition: box-shadow .2s
}

.p-datatable .p-datatable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #343a3f;
    background: #f2f4f8
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #697077;
    margin-left: .5rem
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #44a1d9;
    margin-left: .5rem
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-datatable .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #697077
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f2f4f8;
    color: #000
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #000
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: #dde1e6;
    color: #000
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #000
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 .15rem #90c9f5;
    outline: 0 none
}

.p-datatable .p-datatable-tbody > tr {
    background: #fff;
    color: #343a3f;
    transition: box-shadow .2s
}

.p-datatable .p-datatable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: .25rem 1rem
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus, .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
    margin-right: .5rem
}

.p-datatable .p-datatable-tbody > tr > td > .p-column-title {
    font-weight: 600
}

.p-datatable .p-datatable-tbody > tr > td.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 2px 0 0 #44a1d9
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -2px 0 0 #44a1d9
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-datatable.p-datatable-selectable .p-datatable-tbody > tr.p-selectable-row:focus {
    outline: .15rem solid #90c9f5;
    outline-offset: -0.15rem
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-datatable.p-datatable-selectable-cell .p-datatable-tbody > tr.p-selectable-row > td.p-selectable-cell:focus {
    outline: .15rem solid #90c9f5;
    outline-offset: -0.15rem
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-datatable .p-column-resizer-helper {
    background: #000
}

.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background: #f2f4f8
}

.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-thead, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-virtualscroller > .p-datatable-table > .p-datatable-tfoot {
    background-color: #f2f4f8
}

.p-datatable .p-datatable-loading-icon {
    font-size: 2rem
}

.p-datatable .p-datatable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
    border-width: 0 1px 0 1px
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    border-width: 1px 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
    border-width: 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    border-width: 1px 0 0 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
    border-width: 1px 1px 0 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
    border-width: 1px 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
    border-width: 1px 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
    border-width: 1px 1px 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
    border-width: 0 0 1px 1px
}

.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
    border-width: 0 0 1px 1px
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
    border-width: 0 1px 1px 1px
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
    border-width: 0 0 0 1px
}

.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
    border-width: 0 1px 0 1px
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd {
    background: #fcfcfc
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler {
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight .p-row-toggler:hover {
    color: #fff
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd + .p-row-expanded {
    background: #fcfcfc
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    padding: .125rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-sm .p-datatable-footer {
    padding: .5rem .5rem
}

.p-datatable.p-datatable-lg .p-datatable-header {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
    padding: .3125rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
    padding: 1.25rem 1.25rem
}

.p-datatable.p-datatable-lg .p-datatable-footer {
    padding: 1.25rem 1.25rem
}

.p-datatable-drag-selection-helper {
    background: rgba(0, 0, 0, .16)
}

.p-dataview .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-dataview .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-dataview .p-dataview-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-dataview .p-dataview-content {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    padding: 0
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
    border: solid #dde1e6;
    border-width: 0 0 1px 0
}

.p-dataview .p-dataview-footer {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-dataview .p-dataview-loading-icon {
    font-size: 2rem
}

.p-dataview .p-dataview-loading-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-datascroller .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-datascroller .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-datascroller .p-datascroller-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-datascroller .p-datascroller-content {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    padding: 0
}

.p-datascroller.p-datascroller-inline .p-datascroller-list > li {
    border: solid #dde1e6;
    border-width: 0 0 1px 0
}

.p-datascroller .p-datascroller-footer {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600;
    border-bottom-left-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-column-filter-row .p-column-filter-menu-button, .p-column-filter-row .p-column-filter-clear-button {
    margin-left: .5rem
}

.p-column-filter-menu-button {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-column-filter-menu-button:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
    background: rgba(0, 0, 0, 0);
    color: #343a3f
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
    background: #44a1d9;
    color: #fff
}

.p-column-filter-menu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-column-filter-clear-button {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-column-filter-clear-button:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-column-filter-clear-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-column-filter-overlay {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    min-width: 12.5rem
}

.p-column-filter-overlay .p-column-filter-row-items {
    padding: .25rem 0
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
    margin: 0;
    padding: .25rem .5rem;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 0
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
    color: #343a3f;
    background: #dde1e6
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-column-filter-overlay-menu .p-column-filter-operator {
    padding: .25rem .5rem;
    border-bottom: 0 none;
    color: #343a3f;
    background: #f2f4f8;
    margin: 0;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
    padding: .5rem;
    border-bottom: 1px solid #dee2e6
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
    margin-bottom: .5rem
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
    margin-top: .5rem
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
    border-bottom: 0 none
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
    padding: .5rem 1rem
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
    padding: .5rem
}

.fc.fc-unthemed .fc-view-container th {
    background: #f2f4f8;
    border: 1px solid #dee2e6;
    color: #343a3f
}

.fc.fc-unthemed .fc-view-container td.fc-widget-content {
    border: 1px solid #dee2e6;
    color: #343a3f
}

.fc.fc-unthemed .fc-view-container td.fc-head-container {
    border: 1px solid #dee2e6
}

.fc.fc-unthemed .fc-view-container .fc-view {
    background: #fff
}

.fc.fc-unthemed .fc-view-container .fc-row {
    border-right: 1px solid #dee2e6
}

.fc.fc-unthemed .fc-view-container .fc-event {
    background: #000;
    border: 1px solid #000;
    color: #fff
}

.fc.fc-unthemed .fc-view-container .fc-divider {
    background: #f2f4f8;
    border: 1px solid #dee2e6
}

.fc.fc-unthemed .fc-toolbar .fc-button {
    color: #fff;
    background: #000;
    border: 1px solid #000;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px;
    display: flex;
    align-items: center
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
    background: #000;
    color: #fff;
    border-color: #000
}

.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-unthemed .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #fff;
    border: 1px solid #a5acb3;
    color: #343a3f;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #dde1e6;
    border-color: #a5acb3;
    color: #343a3f
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #000;
    border-color: #000;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #000;
    border-color: #000;
    color: #fff
}

.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    z-index: 1
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
    border-color: #dee2e6
}

.fc.fc-theme-standard .fc-view-harness th {
    background: #f2f4f8;
    border-color: #dee2e6;
    color: #343a3f
}

.fc.fc-theme-standard .fc-view-harness td {
    color: #343a3f;
    border-color: #dee2e6
}

.fc.fc-theme-standard .fc-view-harness .fc-view {
    background: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-popover {
    background: none;
    border: 0 none
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
    border: 1px solid #dee2e6;
    padding: .75rem;
    background: #f2f4f8;
    color: #343a3f
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-family: "PrimeIcons" !important;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
    content: ""
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
    padding: .5rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-top: 0 none
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
    color: #fff;
    background: #000;
    border-color: #000
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
    color: #fff
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
    background: #000;
    border-color: #000
}

.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
    background: #dde1e6;
    color: #343a3f
}

.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
    background: #f2f4f8
}

.fc.fc-theme-standard .fc-toolbar .fc-button {
    color: #fff;
    background: #000;
    border: 1px solid #000;
    font-size: 1rem;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
    border-radius: 1px
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
    background: #000;
    color: #fff;
    border-color: #000
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
    background: #000;
    color: #fff;
    border-color: #000
}

.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
    opacity: .6;
    color: #fff;
    background: #000;
    border: 1px solid #000
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
    font-family: "PrimeIcons" !important;
    text-indent: 0;
    font-size: 1rem
}

.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
    content: ""
}

.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
    background: #fff;
    border: 1px solid #a5acb3;
    color: #343a3f;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
    background: #dde1e6;
    border-color: #a5acb3;
    color: #343a3f
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
    background: #000;
    border-color: #000;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
    background: #000;
    border-color: #000;
    color: #fff
}

.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5;
    z-index: 1
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
    border-radius: 0
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.fc.fc-theme-standard .fc-highlight {
    color: #fff;
    background: #44a1d9
}

.p-orderlist .p-orderlist-controls {
    padding: .5rem
}

.p-orderlist .p-orderlist-controls .p-button {
    margin-bottom: .5rem
}

.p-orderlist .p-orderlist-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dee2e6;
    padding: .75rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-orderlist .p-orderlist-filter-container {
    padding: .75rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
    padding-right: 1.25rem
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
    right: .25rem;
    color: #697077
}

.p-orderlist .p-orderlist-list {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    padding: .25rem 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: .25rem .5rem;
    margin: 0;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: transform .2s, box-shadow .2s
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even) {
    background: #fcfcfc
}

.p-orderlist.p-orderlist-striped .p-orderlist-list .p-orderlist-item:nth-child(even):hover {
    background: #dde1e6
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
    color: #1b5c83
}

.p-organizationchart .p-organizationchart-line-down {
    background: #dee2e6
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #dee2e6;
    border-color: #dee2e6
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #dee2e6;
    border-color: #dee2e6
}

.p-organizationchart .p-organizationchart-node-content {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    padding: .5rem
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-paginator {
    background: #fff;
    color: #697077;
    border: solid #dde1e6;
    border-width: 0;
    padding: .5rem 1rem;
    border-radius: 1px
}

.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #697077;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: .143rem;
    transition: box-shadow .2s;
    border-radius: 1px
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover, .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
    background: #dde1e6;
    border-color: rgba(0, 0, 0, 0);
    color: #343a3f
}

.p-paginator .p-paginator-first {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-paginator .p-paginator-last {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px
}

.p-paginator .p-dropdown {
    margin-left: .5rem;
    height: 2.357rem
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-right: 0
}

.p-paginator .p-paginator-page-input {
    margin-left: .5rem;
    margin-right: .5rem
}

.p-paginator .p-paginator-page-input .p-inputtext {
    max-width: 2.357rem
}

.p-paginator .p-paginator-current {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #697077;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: .143rem;
    padding: 0 .5rem
}

.p-paginator .p-paginator-pages .p-paginator-page {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: #697077;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: .143rem;
    transition: box-shadow .2s;
    border-radius: 1px
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #44a1d9;
    border-color: #44a1d9;
    color: #fff
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: #dde1e6;
    border-color: rgba(0, 0, 0, 0);
    color: #343a3f
}

.p-picklist .p-picklist-buttons {
    padding: .5rem
}

.p-picklist .p-picklist-buttons .p-button {
    margin-bottom: .5rem
}

.p-picklist .p-picklist-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dee2e6;
    padding: .75rem;
    font-weight: 600;
    border-bottom: 0 none;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-picklist .p-picklist-filter-container {
    padding: .75rem;
    background: #fff;
    border: 1px solid #dee2e6;
    border-bottom: 0 none
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
    padding-right: 1.25rem
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
    right: .25rem;
    color: #697077
}

.p-picklist .p-picklist-list {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    padding: .25rem 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-picklist .p-picklist-list .p-picklist-item {
    padding: .25rem .5rem;
    margin: 0;
    border: 0 none;
    color: #343a3f;
    background: rgba(0, 0, 0, 0);
    transition: transform .2s, box-shadow .2s
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
    color: #fff;
    background: #44a1d9
}

.p-tree {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    padding: .5rem;
    border-radius: 1px
}

.p-tree .p-tree-container .p-treenode {
    padding: .143rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
    border-radius: 1px;
    transition: box-shadow .2s;
    padding: .25rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
    margin-right: .5rem;
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
    margin-right: .5rem;
    color: #697077
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
    margin-right: .5rem
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #343a3f
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover, .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
    color: #fff
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
    background: #dde1e6;
    color: #343a3f
}

.p-tree .p-tree-filter-container {
    margin-bottom: .5rem
}

.p-tree .p-tree-filter-container .p-tree-filter {
    width: 100%;
    padding-right: 1.25rem
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
    right: .25rem;
    color: #697077
}

.p-tree .p-treenode-children {
    padding: 0 0 0 1rem
}

.p-tree .p-tree-loading-icon {
    font-size: 2rem
}

.p-tree .p-tree-loading-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-tree .p-treenode-droppoint {
    height: .5rem
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
    background: #2785bd
}

.p-treetable .p-paginator-top {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-treetable .p-paginator-bottom {
    border-width: 0 0 1px 0;
    border-radius: 0
}

.p-treetable .p-treetable-header {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 1px 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-treetable .p-treetable-footer {
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 600
}

.p-treetable .p-treetable-thead > tr > th {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #343a3f;
    background: #f2f4f8;
    transition: box-shadow .2s
}

.p-treetable .p-treetable-tfoot > tr > td {
    text-align: left;
    padding: 1rem 1rem;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #343a3f;
    background: #f2f4f8
}

.p-treetable .p-sortable-column {
    outline-color: #90c9f5
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
    color: #697077;
    margin-left: .5rem
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
    border-radius: 50%;
    height: 1.143rem;
    min-width: 1.143rem;
    line-height: 1.143rem;
    color: #fff;
    background: #44a1d9;
    margin-left: .5rem
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
    color: #697077
}

.p-treetable .p-sortable-column.p-highlight {
    background: #f2f4f8;
    color: #000
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #000
}

.p-treetable .p-treetable-tbody > tr {
    background: #fff;
    color: #343a3f;
    transition: box-shadow .2s
}

.p-treetable .p-treetable-tbody > tr > td {
    text-align: left;
    border: 1px solid #dde1e6;
    border-width: 0 0 1px 0;
    padding: .25rem 1rem
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s;
    margin-right: .5rem
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox {
    margin-right: .5rem
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler + .p-checkbox .p-indeterminate .p-checkbox-icon {
    color: #343a3f
}

.p-treetable .p-treetable-tbody > tr:focus {
    outline: .15rem solid #90c9f5;
    outline-offset: -0.15rem
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
    background: #44a1d9;
    color: #fff
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
    color: #fff
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
    color: #fff
}

.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-treetable.p-treetable-selectable .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler, .p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
    color: #343a3f
}

.p-treetable .p-column-resizer-helper {
    background: #000
}

.p-treetable .p-treetable-scrollable-header, .p-treetable .p-treetable-scrollable-footer {
    background: #f2f4f8
}

.p-treetable .p-treetable-loading-icon {
    font-size: 2rem
}

.p-treetable .p-treetable-loading-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
    border-width: 1px 1px 0 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
    border-width: 0 1px 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
    border-width: 0 1px 0 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
    border-width: 0 1px 1px 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
    border-width: 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
    border-width: 1px
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
    border-width: 1px
}

.p-treetable.p-treetable-sm .p-treetable-header {
    padding: .875rem .875rem
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
    padding: .125rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-sm .p-treetable-footer {
    padding: .5rem .5rem
}

.p-treetable.p-treetable-lg .p-treetable-header {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
    padding: .3125rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
    padding: 1.25rem 1.25rem
}

.p-treetable.p-treetable-lg .p-treetable-footer {
    padding: 1.25rem 1.25rem
}

.p-timeline .p-timeline-event-marker {
    border: 0 none;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: #000
}

.p-timeline .p-timeline-event-connector {
    background-color: #dee2e6
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite, .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0 1rem
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 2px
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite, .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 1rem 0
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
    height: 2px
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: .75rem;
    border: 1px solid #dee2e6;
    color: #343a3f;
    background: #f2f4f8;
    font-weight: 600;
    border-radius: 1px;
    transition: box-shadow .2s
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
    margin-right: .5rem
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
    background: #dde1e6;
    border-color: #dee2e6;
    color: #343a3f
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #f2f4f8;
    border-color: #dee2e6;
    color: #343a3f;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-color: #dee2e6;
    background: #dde1e6;
    color: #343a3f
}

.p-accordion .p-accordion-content {
    padding: .5rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-accordion .p-accordion-tab {
    margin-bottom: 0
}

.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
    border-radius: 0
}

.p-accordion .p-accordion-tab .p-accordion-content {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
    border-top: 0 none
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
    border-top: 0 none
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-card {
    background: #fff;
    color: #343a3f;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    border-radius: 1px
}

.p-card .p-card-body {
    padding: 1rem
}

.p-card .p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: .5rem
}

.p-card .p-card-subtitle {
    font-weight: 400;
    margin-bottom: .5rem;
    color: #697077
}

.p-card .p-card-content {
    padding: 1rem 0
}

.p-card .p-card-footer {
    padding: 1rem 0 0 0
}

.p-fieldset {
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-radius: 1px
}

.p-fieldset .p-fieldset-legend {
    padding: .75rem;
    border: 1px solid #dee2e6;
    color: #343a3f;
    background: #f2f4f8;
    font-weight: 600;
    border-radius: 1px
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
    padding: 0;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
    padding: .75rem;
    color: #343a3f;
    border-radius: 1px;
    transition: box-shadow .2s
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
    margin-right: .5rem
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
    background: #dde1e6;
    border-color: #dee2e6;
    color: #343a3f
}

.p-fieldset .p-fieldset-content {
    padding: .5rem
}

.p-divider .p-divider-content {
    background-color: #fff
}

.p-divider.p-divider-horizontal {
    margin: .5rem 0;
    padding: 0 .5rem
}

.p-divider.p-divider-horizontal:before {
    border-top: 1px #dee2e6
}

.p-divider.p-divider-horizontal .p-divider-content {
    padding: 0 .5rem
}

.p-divider.p-divider-vertical {
    margin: 0 .5rem;
    padding: .5rem 0
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #dee2e6
}

.p-divider.p-divider-vertical .p-divider-content {
    padding: .5rem 0
}

.p-panel .p-panel-header {
    border: 1px solid #dee2e6;
    padding: .75rem;
    background: #f2f4f8;
    color: #343a3f;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-panel .p-panel-header .p-panel-title {
    font-weight: 600
}

.p-panel .p-panel-header .p-panel-header-icon {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-panel.p-panel-toggleable .p-panel-header {
    padding: .5rem 1rem
}

.p-panel .p-panel-content {
    padding: .5rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
    border-top: 0 none
}

.p-panel .p-panel-footer {
    padding: .5rem 1rem;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-top: 0 none
}

.p-splitter {
    border: 1px solid #dee2e6;
    background: #fff;
    border-radius: 1px;
    color: #343a3f
}

.p-splitter .p-splitter-gutter {
    transition: background-color .2s, color .2s, box-shadow .2s;
    background: #f2f4f8
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
    background: #dee2e6
}

.p-splitter .p-splitter-gutter-resizing {
    background: #dee2e6
}

.p-scrollpanel .p-scrollpanel-bar {
    background: #f2f4f8;
    border: 0 none
}

.p-tabview .p-tabview-nav {
    background: #fff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0
}

.p-tabview .p-tabview-nav li {
    margin-right: 0
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dee2e6 rgba(0, 0, 0, 0);
    background: #fff;
    color: #697077;
    padding: .75rem;
    font-weight: 600;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    transition: box-shadow .2s;
    margin: 0 0 -2px 0
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #90c9f5
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #fff;
    border-color: #697077;
    color: #697077
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #fff;
    border-color: #000;
    color: #000
}

.p-tabview .p-tabview-close {
    margin-left: .5rem
}

.p-tabview .p-tabview-nav-btn.p-link {
    background: #fff;
    color: #000;
    width: 2.357rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    border-radius: 0
}

.p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #90c9f5
}

.p-tabview .p-tabview-panels {
    background: #fff;
    padding: .5rem;
    border: 0 none;
    color: #343a3f;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-toolbar {
    background: #f2f4f8;
    border: 1px solid #dee2e6;
    padding: .75rem;
    border-radius: 1px;
    gap: .5rem
}

.p-toolbar .p-toolbar-separator {
    margin: 0 .5rem
}

.p-confirm-popup {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-confirm-popup .p-confirm-popup-content {
    padding: .5rem
}

.p-confirm-popup .p-confirm-popup-footer {
    text-align: right;
    padding: 0 .5rem .5rem .5rem
}

.p-confirm-popup .p-confirm-popup-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
    margin: 0
}

.p-confirm-popup:after {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-confirm-popup:before {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-confirm-popup.p-confirm-popup-flipped:after {
    border-top-color: #fff
}

.p-confirm-popup.p-confirm-popup-flipped:before {
    border-top-color: #fff
}

.p-confirm-popup .p-confirm-popup-icon {
    font-size: 1.5rem
}

.p-confirm-popup .p-confirm-popup-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem
}

.p-confirm-popup .p-confirm-popup-message {
    margin-left: 1rem
}

.p-dialog {
    border-radius: 1px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12);
    border: 0 none
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #fff;
    color: #343a3f;
    padding: .75rem;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 1.25rem
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s;
    margin-right: .5rem
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0
}

.p-dialog .p-dialog-content {
    background: #fff;
    color: #343a3f;
    padding: 0 .75rem 1rem .75rem
}

.p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #fff;
    color: #343a3f;
    padding: 0 .75rem .75rem .75rem;
    text-align: right;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-dialog .p-dialog-footer button {
    margin: 0 .5rem 0 0;
    width: auto
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem
}

.p-overlaypanel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    border-radius: 1px;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-overlaypanel .p-overlaypanel-content {
    padding: .5rem
}

.p-overlaypanel .p-overlaypanel-close {
    background: #000;
    color: #fff;
    width: 1rem;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 50%;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
    background: #000;
    color: #fff
}

.p-overlaypanel:after {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff
}

.p-overlaypanel:before {
    border: solid rgba(0, 0, 0, 0);
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #f2f2f2
}

.p-overlaypanel.p-overlaypanel-flipped:after {
    border-top-color: #fff
}

.p-overlaypanel.p-overlaypanel-flipped:before {
    border-top-color: #fff
}

.p-sidebar {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, .2), 0px 24px 38px 3px rgba(0, 0, 0, .14), 0px 9px 46px 8px rgba(0, 0, 0, .12)
}

.p-sidebar .p-sidebar-header {
    padding: .75rem
}

.p-sidebar .p-sidebar-header .p-sidebar-close, .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 1rem;
    height: 1rem;
    color: #878d96;
    border: 0 none;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover, .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: #343a3f;
    border-color: #121619;
    background: rgba(0, 0, 0, 0)
}

.p-sidebar .p-sidebar-header .p-sidebar-close:focus, .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0
}

.p-sidebar .p-sidebar-content {
    padding: .5rem
}

.p-tooltip .p-tooltip-text {
    background: #343a3f;
    color: #fff;
    padding: .25rem .25rem;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
    border-right-color: #343a3f
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
    border-left-color: #343a3f
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #343a3f
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: #343a3f
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f2f4f8;
    padding: .75rem;
    border: 1px solid #dee2e6;
    color: #343a3f;
    border-bottom: 0 none;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    gap: .5rem
}

.p-fileupload .p-fileupload-buttonbar .p-button {
    margin-right: .5rem
}

.p-fileupload .p-fileupload-content {
    background: #fff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #343a3f;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-fileupload .p-progressbar {
    height: .25rem
}

.p-fileupload .p-fileupload-row > div {
    padding: .25rem 1rem
}

.p-fileupload.p-fileupload-advanced .p-message {
    margin-top: 0
}

.p-breadcrumb {
    background: #fff;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    padding: .5rem
}

.p-breadcrumb ul li .p-menuitem-link {
    transition: box-shadow .2s;
    border-radius: 1px
}

.p-breadcrumb ul li .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: #697077
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    margin: 0 .5rem 0 .5rem;
    color: #343a3f
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
    color: #343a3f
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
    color: #697077
}

.p-contextmenu {
    padding: .25rem 0;
    background: #fff;
    color: #343a3f;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px;
    width: 12.5rem
}

.p-contextmenu .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-contextmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-contextmenu .p-submenu-list {
    padding: .25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #dde1e6
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-contextmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-contextmenu .p-submenu-icon {
    font-size: .875rem;
    transition: transform .2s
}

.p-contextmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-dock .p-dock-list {
    background: rgba(255, 255, 255, .1);
    border: 1px solid rgba(255, 255, 255, .2);
    padding: .5rem .5rem;
    border-radius: .5rem
}

.p-dock .p-dock-item {
    padding: .5rem
}

.p-dock .p-dock-action {
    width: 4rem;
    height: 4rem
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next {
    margin: 0 .9rem
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next {
    margin: 0 1.3rem
}

.p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current {
    margin: 0 1.5rem
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next {
    margin: .9rem 0
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next {
    margin: 1.3rem 0
}

.p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
    margin: 1.5rem 0
}

@media screen and (max-width: 960px) {
    .p-dock.p-dock-top .p-dock-container, .p-dock.p-dock-bottom .p-dock-container {
        overflow-x: auto;
        width: 100%
    }

    .p-dock.p-dock-top .p-dock-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-container .p-dock-list {
        margin: 0 auto
    }

    .p-dock.p-dock-left .p-dock-container, .p-dock.p-dock-right .p-dock-container {
        overflow-y: auto;
        height: 100%
    }

    .p-dock.p-dock-left .p-dock-container .p-dock-list, .p-dock.p-dock-right .p-dock-container .p-dock-list {
        margin: auto 0
    }

    .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-top .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-bottom .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-left .p-dock-item-current, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-second-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-prev, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-next, .p-dock.p-dock-magnification.p-dock-right .p-dock-item-current {
        transform: none;
        margin: 0
    }
}

.p-megamenu {
    padding: .5rem;
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dee2e6;
    border-radius: 1px
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 1px;
    transition: box-shadow .2s;
    user-select: none
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #697077;
    margin-left: .5rem
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-megamenu .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-megamenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-megamenu .p-megamenu-panel {
    background: #fff;
    color: #343a3f;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-megamenu .p-megamenu-submenu-header {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-megamenu .p-megamenu-submenu {
    padding: .25rem 0;
    width: 12.5rem
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #dde1e6
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-megamenu.p-megamenu-vertical {
    width: 12.5rem;
    padding: .25rem 0
}

.p-megamenu .p-megamenu-button {
    width: 1rem;
    height: 1rem;
    color: #697077;
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-megamenu .p-megamenu-button:hover {
    color: #697077;
    background: #dde1e6
}

.p-megamenu .p-megamenu-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-megamenu .p-submenu-icon {
    transition: transform .2s
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list {
    padding: .25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon {
    font-size: .875rem
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem {
    width: 100%;
    position: static
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg)
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-submenu-icon {
    transition: transform .2s;
    transform: rotate(90deg)
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg)
}

.p-megamenu.p-megamenu-mobile-active .p-megamenu-root-list .p-menuitem {
    width: 100%;
    position: static
}

.p-menu {
    padding: .25rem 0;
    background: #fff;
    color: #343a3f;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    width: 12.5rem
}

.p-menu .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-menu .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-menu .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-menu .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-menu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-menu.p-menu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-menu .p-submenu-header {
    margin: 0;
    padding: .25rem .5rem;
    color: #343a3f;
    background: #fff;
    font-weight: 600;
    border-top-right-radius: 0;
    border-top-left-radius: 0
}

.p-menu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-menubar {
    padding: .5rem;
    background: #f2f4f8;
    color: #343a3f;
    border: 1px solid #dee2e6;
    border-radius: 1px
}

.p-menubar .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-menubar .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-menubar .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-menubar .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 1px;
    transition: box-shadow .2s;
    user-select: none
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #697077;
    margin-left: .5rem
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon, .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-menubar .p-submenu-list {
    padding: .25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    width: 12.5rem
}

.p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-menubar .p-submenu-list .p-submenu-icon {
    font-size: .875rem
}

.p-menubar .p-submenu-list .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #dde1e6
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #697077
}

@media screen and (max-width: 960px) {
    .p-menubar {
        position: relative
    }

    .p-menubar .p-menubar-button {
        display: flex;
        width: 1rem;
        height: 1rem;
        color: #697077;
        border-radius: 50%;
        transition: background-color .2s, color .2s, box-shadow .2s
    }

    .p-menubar .p-menubar-button:hover {
        color: #697077;
        background: #dde1e6
    }

    .p-menubar .p-menubar-button:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 .2rem #90c9f5
    }

    .p-menubar .p-menubar-root-list {
        position: absolute;
        display: none;
        padding: .25rem 0;
        background: #fff;
        border: 0 none;
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
        width: 100%
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
        border-top: 1px solid #dee2e6;
        margin: .25rem 0
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
        font-size: .875rem
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon.p-icon {
        width: .875rem;
        height: .875rem
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
        width: 100%;
        position: static
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
        padding: .5rem .5rem;
        color: #343a3f;
        border-radius: 0;
        transition: box-shadow .2s;
        user-select: none
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
        color: #343a3f
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
        color: #697077;
        margin-right: .5rem
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
        color: #697077
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
        background: #dde1e6
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
        color: #343a3f
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
        color: #697077
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
        color: #697077
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: inset 0 0 0 .15rem #90c9f5
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
        margin-left: auto;
        transition: transform .2s
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-180deg)
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
        width: 100%;
        position: static;
        box-shadow: none;
        border: 0 none
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
        transition: transform .2s;
        transform: rotate(90deg)
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
        transform: rotate(-90deg)
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
        width: 100%;
        position: static
    }

    .p-menubar .p-menubar-root-list ul li a {
        padding-left: 1.5rem
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
        padding-left: 2.5rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
        padding-left: 3.5rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
        padding-left: 4.5rem
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
        padding-left: 5.5rem
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
        display: flex;
        flex-direction: column;
        top: 100%;
        left: 0;
        z-index: 1
    }
}

.p-panelmenu .p-panelmenu-header > a {
    padding: .75rem;
    border: 1px solid #dee2e6;
    color: #343a3f;
    background: #f2f4f8;
    font-weight: 600;
    border-radius: 1px;
    transition: box-shadow .2s
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-header > a:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
    background: #dde1e6;
    border-color: #dee2e6;
    color: #343a3f
}

.p-panelmenu .p-panelmenu-header.p-highlight {
    margin-bottom: 0
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
    background: #f2f4f8;
    border-color: #dee2e6;
    color: #343a3f;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
    border-color: #dee2e6;
    background: #dde1e6;
    color: #343a3f
}

.p-panelmenu .p-panelmenu-content {
    padding: .25rem 0;
    border: 1px solid #dee2e6;
    background: #fff;
    color: #343a3f;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
    margin-right: .5rem
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
    padding: 0 0 0 1rem
}

.p-panelmenu .p-panelmenu-panel {
    margin-bottom: 0
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
    border-radius: 0
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
    border-radius: 0
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
    border-top: 0 none
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
    border-top: 0 none
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
    border-top-right-radius: 1px;
    border-top-left-radius: 1px
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.p-slidemenu {
    padding: .25rem 0;
    background: #fff;
    color: #343a3f;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    width: 12.5rem
}

.p-slidemenu .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-slidemenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-slidemenu.p-slidemenu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-slidemenu .p-slidemenu-list {
    padding: .25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
    background: #dde1e6
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
    color: #343a3f
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
    color: #697077
}

.p-slidemenu .p-slidemenu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-slidemenu .p-slidemenu-icon {
    font-size: .875rem
}

.p-slidemenu .p-slidemenu-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-slidemenu .p-slidemenu-backward {
    padding: .5rem .5rem;
    color: #343a3f
}

.p-steps .p-steps-item .p-menuitem-link {
    background: rgba(0, 0, 0, 0);
    transition: box-shadow .2s;
    border-radius: 1px;
    background: #fff
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: #343a3f;
    border: 1px solid #dde1e6;
    background: #fff;
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: .5rem;
    color: #697077
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
    background: #44a1d9;
    color: #fff
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 600;
    color: #343a3f
}

.p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid #dee2e6;
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem
}

.p-tabmenu .p-tabmenu-nav {
    background: #fff;
    border: 1px solid #dee2e6;
    border-width: 0 0 2px 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    margin-right: 0
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    border: solid #dee2e6;
    border-width: 0 0 2px 0;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #dee2e6 rgba(0, 0, 0, 0);
    background: #fff;
    color: #697077;
    padding: .75rem;
    font-weight: 600;
    border-top-right-radius: 1px;
    border-top-left-radius: 1px;
    transition: box-shadow .2s;
    margin: 0 0 -2px 0;
    height: calc(100% + 2px)
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
    margin-right: .5rem
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .2rem #90c9f5
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background: #fff;
    border-color: #697077;
    color: #697077
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background: #fff;
    border-color: #000;
    color: #000
}

.p-tieredmenu {
    padding: .25rem 0;
    background: #fff;
    color: #343a3f;
    border: 1px solid #dee2e6;
    border-radius: 1px;
    width: 12.5rem
}

.p-tieredmenu .p-menuitem-link {
    padding: .5rem .5rem;
    color: #343a3f;
    border-radius: 0;
    transition: box-shadow .2s;
    user-select: none
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
    color: #697077;
    margin-right: .5rem
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: #dde1e6
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #343a3f
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #697077
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #697077
}

.p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 .15rem #90c9f5
}

.p-tieredmenu.p-tieredmenu-overlay {
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-tieredmenu .p-submenu-list {
    padding: .25rem 0;
    background: #fff;
    border: 0 none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12)
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: #dde1e6
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: #343a3f
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: #697077
}

.p-tieredmenu .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: .25rem 0
}

.p-tieredmenu .p-submenu-icon {
    font-size: .875rem;
    transition: transform .2s
}

.p-tieredmenu .p-submenu-icon.p-icon {
    width: .875rem;
    height: .875rem
}

.p-inline-message {
    padding: .25rem .25rem;
    margin: 0;
    border-radius: 1px
}

.p-inline-message.p-inline-message-info {
    background: #cdf2fb;
    border: solid #11add3;
    border-width: 0px;
    color: #08576a
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
    color: #08576a
}

.p-inline-message.p-inline-message-success {
    background: #d7f8d3;
    border: solid #32c620;
    border-width: 0px;
    color: #196310
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
    color: #196310
}

.p-inline-message.p-inline-message-warn {
    background: #fef8d6;
    border: solid #e4c306;
    border-width: 0px;
    color: #726203
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
    color: #726203
}

.p-inline-message.p-inline-message-error {
    background: #fdded2;
    border: solid #de450a;
    border-width: 0px;
    color: #6f2205
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
    color: #6f2205
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-right: .5rem
}

.p-inline-message .p-inline-message-icon.p-icon {
    width: 1rem;
    height: 1rem
}

.p-inline-message .p-inline-message-text {
    font-size: 1rem
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
    margin-right: 0
}

.p-message {
    margin: 1rem 0;
    border-radius: 1px
}

.p-message .p-message-wrapper {
    padding: 1rem 1.5rem
}

.p-message .p-message-close {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-message .p-message-close:hover {
    background: rgba(255, 255, 255, .3)
}

.p-message .p-message-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-message.p-message-info {
    background: #cdf2fb;
    border: solid #11add3;
    border-width: 0 0 0 6px;
    color: #08576a
}

.p-message.p-message-info .p-message-icon {
    color: #08576a
}

.p-message.p-message-info .p-message-close {
    color: #08576a
}

.p-message.p-message-success {
    background: #d7f8d3;
    border: solid #32c620;
    border-width: 0 0 0 6px;
    color: #196310
}

.p-message.p-message-success .p-message-icon {
    color: #196310
}

.p-message.p-message-success .p-message-close {
    color: #196310
}

.p-message.p-message-warn {
    background: #fef8d6;
    border: solid #e4c306;
    border-width: 0 0 0 6px;
    color: #726203
}

.p-message.p-message-warn .p-message-icon {
    color: #726203
}

.p-message.p-message-warn .p-message-close {
    color: #726203
}

.p-message.p-message-error {
    background: #fdded2;
    border: solid #de450a;
    border-width: 0 0 0 6px;
    color: #6f2205
}

.p-message.p-message-error .p-message-icon {
    color: #6f2205
}

.p-message.p-message-error .p-message-close {
    color: #6f2205
}

.p-message .p-message-text {
    font-size: 1rem;
    font-weight: 500
}

.p-message .p-message-icon {
    font-size: 1.5rem;
    margin-right: .5rem
}

.p-message .p-message-icon.p-icon {
    width: 1.5rem;
    height: 1.5rem
}

.p-toast {
    opacity: .9
}

.p-toast .p-toast-message {
    margin: 0 0 1rem 0;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    border-radius: 1px
}

.p-toast .p-toast-message .p-toast-message-content {
    padding: 1rem;
    border-width: 0 0 0 6px
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
    margin: 0 0 0 1rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
    font-size: 2rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
    font-weight: 700
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    margin: .5rem 0 0 0
}

.p-toast .p-toast-message .p-toast-icon-close {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0);
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
    background: rgba(255, 255, 255, .3)
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-toast .p-toast-message.p-toast-message-info {
    background: #cdf2fb;
    border: solid #11add3;
    border-width: 0 0 0 6px;
    color: #08576a
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
    color: #08576a
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #d7f8d3;
    border: solid #32c620;
    border-width: 0 0 0 6px;
    color: #196310
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
    color: #196310
}

.p-toast .p-toast-message.p-toast-message-warn {
    background: #fef8d6;
    border: solid #e4c306;
    border-width: 0 0 0 6px;
    color: #726203
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
    color: #726203
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #fdded2;
    border: solid #de450a;
    border-width: 0 0 0 6px;
    color: #6f2205
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon, .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
    color: #6f2205
}

.p-galleria .p-galleria-close {
    margin: .5rem;
    background: rgba(0, 0, 0, 0);
    color: #f2f4f8;
    width: 4rem;
    height: 4rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 50%
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-close .p-galleria-close-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-galleria .p-galleria-close:hover {
    background: rgba(255, 255, 255, .1);
    color: #f2f4f8
}

.p-galleria .p-galleria-item-nav {
    background: rgba(0, 0, 0, 0);
    color: #f2f4f8;
    width: 4rem;
    height: 4rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 1px;
    margin: 0 .5rem
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
    font-size: 2rem
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon.p-icon, .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon.p-icon {
    width: 2rem;
    height: 2rem
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
    background: rgba(255, 255, 255, .1);
    color: #f2f4f8
}

.p-galleria .p-galleria-caption {
    background: rgba(0, 0, 0, .5);
    color: #f2f4f8;
    padding: 1rem
}

.p-galleria .p-galleria-indicators {
    padding: 1rem
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
    background-color: #dde1e6;
    width: 1rem;
    height: 1rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 50%
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
    background: #dee2e6
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #44a1d9;
    color: #fff
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
    margin-right: .5rem
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
    margin-bottom: .5rem
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: rgba(0, 0, 0, .5)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(255, 255, 255, .4)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
    background: rgba(255, 255, 255, .6)
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: #44a1d9;
    color: #fff
}

.p-galleria .p-galleria-thumbnail-container {
    background: rgba(0, 0, 0, .9);
    padding: 1rem .25rem
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    margin: .5rem;
    background-color: rgba(0, 0, 0, 0);
    color: #f2f4f8;
    width: 2rem;
    height: 2rem;
    transition: background-color .2s, color .2s, box-shadow .2s;
    border-radius: 50%
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
    background: rgba(255, 255, 255, .1);
    color: #f2f4f8
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
    transition: box-shadow .2s
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-galleria-mask {
    --maskbg: rgba(0, 0, 0, 0.9)
}

.p-image-mask {
    --maskbg: rgba(0, 0, 0, 0.9)
}

.p-image-preview-indicator {
    background-color: rgba(0, 0, 0, 0);
    color: #f8f9fa;
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-image-preview-indicator .p-icon {
    width: 1.5rem;
    height: 1.5rem
}

.p-image-preview-container:hover > .p-image-preview-indicator {
    background-color: rgba(0, 0, 0, .5)
}

.p-image-toolbar {
    padding: 1rem
}

.p-image-action.p-link {
    color: #f8f9fa;
    background-color: rgba(0, 0, 0, 0);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    transition: background-color .2s, color .2s, box-shadow .2s;
    margin-right: .5rem
}

.p-image-action.p-link:last-child {
    margin-right: 0
}

.p-image-action.p-link:hover {
    color: #f8f9fa;
    background-color: rgba(255, 255, 255, .1)
}

.p-image-action.p-link span {
    font-size: 1.5rem
}

.p-image-action.p-link .p-icon {
    width: 1.5rem;
    height: 1.5rem
}

.p-avatar {
    background-color: #dee2e6;
    border-radius: 1px
}

.p-avatar.p-avatar-lg {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem
}

.p-avatar.p-avatar-lg .p-avatar-icon {
    font-size: 1.5rem
}

.p-avatar.p-avatar-xl {
    width: 4rem;
    height: 4rem;
    font-size: 2rem
}

.p-avatar.p-avatar-xl .p-avatar-icon {
    font-size: 2rem
}

.p-avatar-group .p-avatar {
    border: 2px solid #fff
}

.p-chip {
    background-color: #dee2e6;
    color: #343a3f;
    border-radius: 16px;
    padding: 0 .25rem
}

.p-chip .p-chip-text {
    line-height: 1.5;
    margin-top: .125rem;
    margin-bottom: .125rem
}

.p-chip .p-chip-icon {
    margin-right: .5rem
}

.p-chip img {
    width: 1.75rem;
    height: 1.75rem;
    margin-left: -0.25rem;
    margin-right: .5rem
}

.p-chip .p-chip-remove-icon {
    border-radius: 1px;
    transition: background-color .2s, color .2s, box-shadow .2s;
    margin-left: .5rem
}

.p-chip .p-chip-remove-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-scrolltop {
    width: 2rem;
    height: 2rem;
    border-radius: 1px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    transition: background-color .2s, color .2s, box-shadow .2s
}

.p-scrolltop.p-link {
    background: rgba(0, 0, 0, .7)
}

.p-scrolltop.p-link:hover {
    background: rgba(0, 0, 0, .8)
}

.p-scrolltop .p-scrolltop-icon {
    font-size: 1rem;
    color: #f2f4f8
}

.p-scrolltop .p-scrolltop-icon.p-icon {
    width: 1rem;
    height: 1rem
}

.p-skeleton {
    background-color: #dde1e6;
    border-radius: 1px
}

.p-skeleton:after {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))
}

.p-tag {
    background: #000;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem;
    border-radius: 1px
}

.p-tag.p-tag-success {
    background-color: #207f3b;
    color: #fff
}

.p-tag.p-tag-info {
    background-color: #107d79;
    color: #fff
}

.p-tag.p-tag-warning {
    background-color: #f0c135;
    color: #212529
}

.p-tag.p-tag-danger {
    background-color: #d8222f;
    color: #fff
}

.p-tag .p-tag-icon {
    margin-right: .25rem;
    font-size: .75rem
}

.p-tag .p-tag-icon.p-icon {
    width: .75rem;
    height: .75rem
}

.p-inplace .p-inplace-display {
    padding: .25rem .25rem;
    border-radius: 1px;
    transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: #dde1e6;
    color: #343a3f
}

.p-inplace .p-inplace-display:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 .2rem #90c9f5
}

.p-progressbar {
    border: 0 none;
    height: 1.5rem;
    background: #dee2e6;
    border-radius: 1px
}

.p-progressbar .p-progressbar-value {
    border: 0 none;
    margin: 0;
    background: #000
}

.p-progressbar .p-progressbar-label {
    color: #fff;
    line-height: 1.5rem
}

.p-terminal {
    background: #fff;
    color: #343a3f;
    border: 1px solid #dee2e6;
    padding: .5rem
}

.p-terminal .p-terminal-input {
    font-size: 1rem;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
}

.p-badge {
    background: #000;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem
}

.p-badge.p-badge-secondary {
    background-color: #697077;
    color: #fff
}

.p-badge.p-badge-success {
    background-color: #207f3b;
    color: #fff
}

.p-badge.p-badge-info {
    background-color: #107d79;
    color: #fff
}

.p-badge.p-badge-warning {
    background-color: #f0c135;
    color: #212529
}

.p-badge.p-badge-danger {
    background-color: #d8222f;
    color: #fff
}

.p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem
}

.p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem
}

.p-tag {
    background: #000;
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    padding: .25rem .4rem;
    border-radius: 1px
}

.p-tag.p-tag-success {
    background-color: #207f3b;
    color: #fff
}

.p-tag.p-tag-info {
    background-color: #107d79;
    color: #fff
}

.p-tag.p-tag-warning {
    background-color: #f0c135;
    color: #212529
}

.p-tag.p-tag-danger {
    background-color: #d8222f;
    color: #fff
}