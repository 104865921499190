.app {
    height: 100%;
    overflow: hidden;
    display: grid;
}

.configure {
    justify-self: center;
    align-self: center;
}

.buttons {
    display: flex;
    gap: 0.75rem;
}

.input {
    font-size: 1.1rem;
    border: none;
}

.editor {
    box-sizing: border-box;
    border-radius: 0;
}

.blocknote {
    height: calc(100% - 42px);
    overflow-y: scroll;
}